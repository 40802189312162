import React, {useState, useMemo} from 'react'
import PropTypes from 'prop-types'
import LayoutContext from '../../contexts/LayoutContext'
import Navbar from '../../components/Navbar'
import styles from './styles.module.css'

const propTypes = {
  children: PropTypes.node,
}
const defaultProps = {}

function Layout(props) {
  const [showNavbar, setShowNavbar] = useState(true)
  const [backgroundColor, setBackgroundColor] = useState('#F7F8FC')

  const layoutContextValue = useMemo(
    () => ({
      showNavbar: () => setShowNavbar(true),
      hideNavbar: () => setShowNavbar(false),
      setBackgroundColor,
    }),
    [setBackgroundColor, setShowNavbar],
  )

  const menu = useMemo(() => [
    {to: '/', label: 'Home'},
    {to: '/messager', label: 'Messager'}
  ], [])

  return (
    <LayoutContext.Provider value={layoutContextValue}>
      <div style={{backgroundColor}} className={styles.container}>
        {showNavbar && <Navbar menu={menu} />}
        {props.children}
      </div>
    </LayoutContext.Provider>
  )
}

Layout.propTypes = propTypes
Layout.defaultProps = defaultProps

export default Layout
