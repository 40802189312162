import React, {useContext, useEffect, useState} from 'react'
import {useCookies} from 'react-cookie'
import PropTypes from 'prop-types'
import FirebaseContext from '../../contexts/FirebaseContext'

const propTypes = {
  children: PropTypes.node,
}
const ForceLogin = props => {
  const firebase = useContext(FirebaseContext)
  const [cookie] = useCookies(['admin-token'])
  const [, setLogged] = useState(true)
  useEffect(() => {
    async function getUserAsync() {
      if (!cookie['admin-token']) {
        setLogged(false)
        return
      }
      const {data} = {data: {signinToken: 'token'}}
      try {
        await firebase.auth().signInWithCustomToken(data.signinToken)
        setLogged(true)
      } catch (e) {
        setLogged(false)
      }
    }
    getUserAsync()
  }, [cookie, firebase])
  if (true) {
    return (
      <>
        {props.children}
      </>
    )
  }
  window.location.href = process.env.REACT_APP_REDIRECT_LOGIN
  return null
}
ForceLogin.propTypes = propTypes
export default ForceLogin
