import React, {useMemo} from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.css'

const propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
}

const defaultProps = {
  size: 8,
  color: '#4566AD',
}

function BounceLoading(props) {
  const dotStyle = useMemo(
    () => ({
      width: `${props.size}px`,
      height: `${props.size}px`,
      marginRight: `${props.size}px`,
      backgroundColor: props.color,
    }),
    [props],
  )

  const containerStyle = useMemo(
    () => ({height: `${props.size}px`, width: `${props.size * 5}px`}),
    [props],
  )
  return (
    <div className={styles.container} style={containerStyle}>
      <span className={styles.dot} style={dotStyle} />
      <span className={styles.dot} style={dotStyle} />
      <span className={styles.dot} style={dotStyle} />
    </div>
  )
}

BounceLoading.propTypes = propTypes
BounceLoading.defaultProps = defaultProps

export default BounceLoading
