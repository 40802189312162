export default {
  name: 'SAT',
  firebaseConfig: {
    apiKey: 'AIzaSyCvQvYrIkOOH6SOrIETtUyeuDgEIopohjE',
    authDomain: 'sat2-prod.firebaseapp.com',
    databaseURL: 'https://sat2-prod.firebaseio.com',
    projectId: 'sat2-prod',
    storageBucket: 'sat2-prod.appspot.com',
    messagingSenderId: '146492734678',
    appId: '1:146492734678:web:1ea3cc7be08d1524',
  },
  root: 'sat2-prod',
  usersArea: 'sat_app',
  educatorsArea: 'sat_dashboard',
}
