import React from 'react'
import {BrowserRouter} from 'react-router-dom'
import Root from './Root'
import Pages from './Pages'

const propTypes = {}
const defaultProps = {}

const App = () => {
  return (
    <BrowserRouter>
      <Root>
        <Pages />
      </Root>
    </BrowserRouter>
  )
}

App.propTypes = propTypes
App.defaultProps = defaultProps
export default App
