import act from './act'
import sat from './sat'
import satsm from './satsm'
import toefl from './toefl'

const platforms = {
  act,
  sat,
  satsm,
  toefl,
}

Object.keys(platforms).forEach(platformId => {
  platforms[platformId].id = platformId
})

export default platforms
