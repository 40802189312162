export default {
  name: 'ACT',
  firebaseConfig: {
    apiKey: 'AIzaSyBSJYlq3lKNSECqzMov1faljKMsqnYSvBY',
    authDomain: 'act-prod.firebaseapp.com',
    databaseURL: 'https://act-prod.firebaseio.com',
    projectId: 'act-prod',
    storageBucket: 'act-prod.appspot.com',
    messagingSenderId: '14882568517',
    appId: '1:14882568517:web:434c75aab5b791c7',
  },
  root: 'act-prod',
  usersArea: 'act_app',
  educatorsArea: 'act_dashboard',
}
