export default {
  name: 'TOEFL',
  firebaseConfig: {
    apiKey: 'AIzaSyA3k2SriWp_riehcWnY6KM32WmcXVfH83I',
    authDomain: 'znk-toefl-prod.firebaseapp.com',
    databaseURL: 'https://znk-toefl-prod.firebaseio.com',
    projectId: 'znk-toefl-prod',
    storageBucket: 'znk-toefl-prod.appspot.com',
    messagingSenderId: '657999325066',
    appId: '1:657999325066:web:ca58372c4ea19c84',
  },
  root: 'znk-toefl-prod',
  usersArea: 'toefl_app',
  educatorsArea: 'toefl_dashboard',
}
