import React from 'react'
import PropTypes from 'prop-types'
import {CookiesProvider} from 'react-cookie'
import RouterProvider from './RouterProvider'
import FirebaseProvider from './FirebaseProvider'

const propTypes = {
  children: PropTypes.node,
}
const defaultProps = {}

function Providers(props) {
  return (
    <FirebaseProvider>
      <CookiesProvider>
        <RouterProvider>{props.children}</RouterProvider>
      </CookiesProvider>
    </FirebaseProvider>
  )
}

Providers.propTypes = propTypes
Providers.defaultProps = defaultProps

export default Providers
