import React, {useMemo} from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import getClassNames from '../../helpers/styles/getClassNames'
import styles from './styles.module.css'

const propTypes = {
  menu: PropTypes.array,
}
const defaultProps = {
  menu: [],
}

function Navbar(props) {
  const showRight = useMemo(() => props.menu.length > 0, [props.menu])

  return (
    <div className={styles.container}>
      <div className={getClassNames(styles.section, styles.left)}>
        <img className={styles.logo} src="/imgs/logo.png" alt="" />
        <div className={styles.name}>Zinkerz</div>
      </div>
      {showRight && (
        <div className={getClassNames(styles.section, styles.right)}>
          <div className={styles.divider} />
          {props.menu.map(option => (
            <Link key={`${option.to}.${option.label}`} className={styles.link} to={option.to}>
              {option.label}
            </Link>
          ))}
        </div>
      )}
    </div>
  )
}

Navbar.propTypes = propTypes
Navbar.defaultProps = defaultProps

export default Navbar
