export default {
  name: 'SATSM',
  firebaseConfig: {
    apiKey: 'AIzaSyCN9R60RZrVnnYb-Nvq_jqGjUAVkNROs_g',
    authDomain: 'znk-satsm-prod.firebaseapp.com',
    databaseURL: 'https://znk-satsm-prod.firebaseio.com',
    projectId: 'znk-satsm-prod',
    storageBucket: 'znk-satsm-prod.appspot.com',
    messagingSenderId: '544074616203',
    appId: '1:544074616203:web:f39249c8972a9284',
  },
  root: 'znk-satsm-prod',
  usersArea: 'satsm_app',
  educatorsArea: 'satsm_dashboard',
}
