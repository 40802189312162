import React, {Suspense, lazy} from 'react'
import {Switch, Route} from 'react-router-dom'
import Layout from './Layout'
import BounceLoading from "../components/BounceLoading";

const Home = lazy(() => import('./Home'))
const Messager = lazy(() => import('./Messager'))

const propTypes = {}
const defaultProps = {}
const Pages = () => {
  return (
    <Suspense fallback={<BounceLoading/>}>
      <Layout>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/messager" exact component={Messager} />
        </Switch>
      </Layout>
    </Suspense>
  )
}

Pages.propTypes = propTypes
Pages.defaultProps = defaultProps
export default Pages
