import React, {useMemo} from 'react'
import PropTypes from 'prop-types'
import firebase from 'firebase/app'
import 'firebase/auth'
import FirebaseContext from '../../../contexts/FirebaseContext'
import platforms from '../../../helpers/data/platforms'
import firebaseOptions from './firebaseOptions'

const propTypes = {
  children: PropTypes.any,
}

function FirebaseProvider({children}) {
  const app = useMemo(() => {
    const result = firebase.initializeApp(firebaseOptions)

    Object.values(platforms).forEach(platform => {
      result[platform.id] = firebase.initializeApp(platform.firebaseConfig, platform.id)
    })

    global.firebaseProvider = result

    return result
  }, [])

  return <FirebaseContext.Provider value={app}>{children}</FirebaseContext.Provider>
}

FirebaseProvider.propTypes = propTypes

export default FirebaseProvider
