import React from 'react'
import PropTypes from 'prop-types'
import Providers from './Providers'
import ForceLogin from './ForceLogin'

const propTypes = {
  children: PropTypes.node,
}
const defaultProps = {}

function Root(props) {
  return (
    <Providers>
      <ForceLogin>
        {props.children}
      </ForceLogin>
    </Providers>
  )
}

Root.propTypes = propTypes
Root.defaultProps = defaultProps

export default Root
